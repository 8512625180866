import * as actionType from "../constants/actionTypes";

const videosubmissionReducer = (state = { videosubmissionsData: null }, action) => {
	switch (action.type) {
		
		case actionType.VIDEOSUBMISSION:
			return {...state,videosubmission: action.payload};
		case actionType.VIDEOSUBMISSIONDETAILS:
			return {...state,videosubmissionDetails : action.payload}
			
		default:
			return state;
	}
};

export default videosubmissionReducer;
