
import { MESSAGE,LOCALITY } from '../../constants/actionTypes';
import * as api from '../../api/index.js';

export const locality_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.locality_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const locality_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.locality_update(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const locality_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.locality_delete(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const locality_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.locality_list(formData);
      
      dispatch({ type: LOCALITY, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  