import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import City from '../components/Masters/City/City';

function CityModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateCity' element={<Layout children={City()} />} />
                < Route path='/EditCity' element={<Layout children={City()} />} />
                < Route path='/City' element={<Layout children={City()} />} />
            </Routes>
        </div>
    )
}
export default CityModule