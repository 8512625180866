import * as React from "react";
import { MESSAGE,PROFILE,
  LOGGEDIN } from '../../../constants/actionTypes';
import {
  IconButton,
  Typography,
  Box,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from "@mui/material";
import {Link} from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import profile_pic from './../../../images/logo.png'
import { useNavigate } from "react-router-dom";
import { IMAGE } from "../../../api";
import { useSelector,useDispatch } from "react-redux";
import { forgot_password } from "../../../actions/authenticate";
const Profile = () => {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const user=useSelector((state) => state.layout.profile)

  const handleOtp =()=>{
    dispatch(forgot_password({
      username:user?.email
    }))
  }
 
 
  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ p: 0 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className="ml-2"
        >
          <Avatar
            src={profile_pic}
            alt="Adison Jeck"
            width={"20px"}
            sx={{ width: 40}}
          />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "10px",
            boxShadow: "0px 10px 35px rgba(50, 110, 189, 0.2)",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        className="for-dark-top-navList"
      >
        <MenuItem>
          <Avatar src={profile_pic} className="mr-1" />
          <Box>
            <Typography sx={{ fontSize: "11px", color: "#757FEF" }}>
              Admin
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                color: "#260944",
                fontWeight: "500",
              }}
            >
              {/* {user?.firstName} */}
              {/* Behindwood */}
              <span>Locality 360</span>
              
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                color: "#260944",
                fontWeight: "500",
              }}
            >
              {user?.email}
              {/* ott.dev.nian@gmail.com */}
              
            </Typography>
          </Box>
        </MenuItem>

        <Divider />

     

        

       

        <MenuItem>
          <ListItemIcon sx={{ mr: "-8px", mt: "-3px" }}>
            <Settings fontSize="small" />
          </ListItemIcon>
          <Link
            to="/Authentication/Otp/"
            fontSize="13px"
            color="black"
            underline="none"
            state={user?.email}
          >
            <span onClick={handleOtp}>Change Password</span>
          </Link>
        </MenuItem>


        <Divider />

        <MenuItem 
            onClick={()=>{
              localStorage.setItem("remember_me", "false")
              localStorage.setItem("loginDetails","{}")
              localStorage.setItem("loggedInDetails","{}")
        dispatch({ type: PROFILE, payload:{} });
      
        dispatch({ type: LOGGEDIN, payload:false });
          navigate("/",{state:{message:"Logged Out Successfully"}})
        }
        }>
          <ListItemIcon sx={{ mr: "-8px", mt: "-3px" }}>
            <Logout fontSize="small" />
          </ListItemIcon>

          <p
           
            
        
            fontSize="13px"
            color="black"
            underline="none"
          >
            Logout
          </p>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Profile;
