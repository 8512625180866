import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import ViewChange from "../../utils/ViewChange";
import {
  genre_create,
  genre_delete,
  genre_update,
  all_genre_list,
} from "../../../actions/Masters/genre";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
export default function Tags() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate()
  const [isEdit, setIsEdit] = useState(false);
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
    
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
	const [open, setOpen] = useState(false);
	const genre_msg = useSelector((state) => state?.masters?.genre_msg);

  useMemo(() => {
    if (isEdit) {
      setView("create_new");
    } else {
      setView("view_all");
      setForm({});
    }
  }, [isEdit]);
  const path = location?.pathname.split("/")[3];
  useEffect(() => {
    setView(path != "Tag" ? "create_new" : "view_all");
    setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    setIsEdit(path == "EditTag");
  }, [location]);
  const [tableData, setTableData] = useState({
    tableTitle: "Tags",
    deleteRecord: genre_delete,
    updateRecord: genre_update,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: "tag_title",
        numeric: false,
        disablePadding: true,
        label: "Tags",
      },
      {
        id: "tag_image",
        label: "Image",
        isImage: true,
      },

      {
        id: "edit",
        label: "Update",
      },
    ],
    tableBody: [],
  });
  const formStructure = [
    {
      id: "1",
      type: "inputBox",
      title: "Add Tag",
      name: "tag_title",
      maxLength: 15,
      regex: /^[a-zA-Z\s\&\-]+$/,
      required: true,
    },

    { type: "undefined" },
    {
      id: "3",
      type: "image",
      title: "Image",
      name: "tag_image",
      subtitle: "(Resolution : 512px x 512px) *",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      subsubtitle: "Max File Size 1MB",
    },

    {
      id: "5",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ];

  const genre = useSelector((state) => state.masters.genre);
  useEffect(() => {
    dispatch(all_genre_list());
  }, []);
  useMemo(() => {
    if (genre?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = genre?.data;
      setTableData({ ...temp });
    }
  }, [genre]);

  const formTitle = isEdit ? "Edit Tag" : "Create Tag";

  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));

    const temp = tableData;
    if (isEdit) {
      dispatch(genre_update(data));
    } else {
      dispatch(genre_create(data));
    }
  };
  useMemo(() => {
    if (genre_msg?.statuscode == 200) {
      setIsEdit(false);
      setOpen(true)
      setForm({});
      setTimeout(() => {
        dispatch(all_genre_list());
		navigate("/Masters/Tag/Tag", { state: { view: "view_all" } })
				setView("view_all");
      }, 1000);
    } else {
      setForm({ ...form });
    }
  }, [genre_msg]);
  const handleClose = () => {
		setOpen(false);
		// dispatch({ type: MESSAGE, payload: undefined });
	  };
  return (
    <>
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert severity="info" variant="filled" color="success">
            {genre_msg?.message}
          </Alert>
        </Snackbar>
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Masters/Tag/CreateTag"}
        view_all={"/Masters/Tag/Tag"}
      />

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Masters/Tag/EditTag"}
        />
      )}
    </>
  );
}
