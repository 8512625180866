
import { MESSAGE, VIDEOSUBMISSION ,VIDEOSUBMISSIONDETAILS} from './../constants/actionTypes';
import * as api from './../api/index.js';



  export const video_submission_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.video_submission_list(formData);
      
      dispatch({ type: VIDEOSUBMISSION, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const video_submission_status_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.video_submission_status_update(formData);

      dispatch({type : MESSAGE , payload: data});
    } catch (error) {
      console.log(error)
    }
  }
  export const video_submission_details = (formData) => async (dispatch) => {
    try {
      const { data } = await api.video_submission_details(formData);

      dispatch({type : VIDEOSUBMISSIONDETAILS , payload: data});
    } catch (error) {
      console.log(error)
    }
  }