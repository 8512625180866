import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import VideoSubmission from "../components/VideoSubmission/VideoSubmission";
import VideoSubmissionDetails from "../components/VideoSubmission/VideoSubmissionDetails";
function VideoSubmissionModule() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/VideoSubmission"
          element={<Layout children={VideoSubmission()} />}
        />
        <Route
          path="/VideoSubmissionDetails"
          element={<Layout children={VideoSubmissionDetails()} />}
        />
      </Routes>
    </div>
  );
}

export default VideoSubmissionModule;
