

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Video from '../components/Video/Video';
import ProductDetails from "../components/ProductDetails/ProductDetailsContent"
function VideoModule() {
  return (
    <div className="App">
    
<Routes>
< Route path='/Video' element={<Layout children={Video()}/>}/>
< Route path='/CreateVideo' element={<Layout children={Video()}/>}/>
< Route path='/EditVideo' element={<Layout children={Video()}/>}/>

{/* < Route path='/UpcomingMovie' element={<Layout children={UpcomingMovie()}/>}/>
< Route path='/CreateUpcomingMovie' element={<Layout children={UpcomingMovie()}/>}/>
< Route path='/EditUpcomingMovie' element={<Layout children={UpcomingMovie()}/>}/> */}



< Route path='/VideoDetails' element={<Layout children={ProductDetails()}/>}/>
      </Routes>
    </div>
  );
}

export default VideoModule;
