import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
function LayoutModule() {
  return (
    <div className="App">
      <Routes>
        {/* Test */}
        <Route path="/" element={<Layout key="Layout" />} />
        {/* test */}
        <Route path="/Layout" element={<Layout key="Layout" />} />
      </Routes>
    </div>
  );
}

export default LayoutModule;
