
import { CITYLIST, MESSAGE, STATELIST } from '../constants/actionTypes'
import * as api from '../api/index.js';




  export const state_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.state_list(formData);
      dispatch({ type: STATELIST, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const city_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.city_create(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const city_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.city_delete(formData);
      dispatch({ type: MESSAGE, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const city_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.city_list(formData);
      dispatch({ type: CITYLIST, payload:data });
  
   
    } catch (error) {
      console.log(error);
    }
  };
  