import { Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import Tags from '../components/Masters/Tags/Tags';
function TagModule() {
    return (
        <div className='App'>
            <Routes>
                < Route path='/CreateTag' element={<Layout children={Tags()} />} />
                < Route path='/EditTag' element={<Layout children={Tags()} />} />
                < Route path='/Tag' element={<Layout children={Tags()} />} />
            </Routes>
        </div>
    )
}
export default TagModule